<template>
  <div id="container">
    <section id="login">
      <div class="wrap">
        <h2 class="cts_ttl"><span>複眼経済塾にログインする</span></h2>
        <div class="cts_box">
          <form @submit="submitClicked">
            <div class="login_box">
              <div class="input_set">
                <div class="label">登録メールアドレス</div>
                <input type="text" v-model="email" v-bind:class="{error: errors}">
              </div>
              <div class="input_set">
                <div class="label">パスワード</div>
                <input v-bind:type="password_type ? 'text' : 'password'" v-model="password"
                       @input="password = replaceFullToHalf(password)"
                       v-bind:class="{error: errors}">

                <div class="checkbox mt10">
                  <input id="check01" type="checkbox" v-model="password_type">
                  <label for="check01">入力内容を表示</label>
                </div>
                <validation-errors :errors="errors" v-if="errors"/>
              </div>
            </div>

            <p class="alnC mb20">メールアドレスとパスワードを入力の上「ログイン」ボタンを押してください</p>
            <div class="button_wrap">
              <input type="submit" class="submit basic" value="ログイン">
            </div>
          </form>
        </div>

        <div class="cts_box alnC">
          <p>パスワードを忘れた方はこちら</p>
          <router-link :to="{ name: 'UsersNewPassword'}" class="red no_bdr bold">ログインできない場合</router-link>
        </div>

        <div class="cts_box alnC" v-if="isRegistrationEnabled()">
          <p>複眼経済塾に入塾を希望する方はこちら</p>
          <router-link :to="{ name: 'UsersRegistration'}" class="no_bdr bold">新規入塾申し込み</router-link>
        </div>
      </div><!--//.wrap-->
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import ValidationErrors from "@/components/ValidationErrors";

export default {
  mixins: [Libraries],
  components: {
    ValidationErrors
  },
  data() {
    return {
      email: null,
      password: null,
      password_type: false,
      errors: null
    }
  },
  mounted() {
    if (this.isUserSignedIn()) {
      // ログイン中ならマイページへ
      this.$router.push({name: 'UsersCustomerAccount'})
    }
  },
  created() {
  },
  methods: {
    submitClicked(event) {
      event.preventDefault()
      this.errors = null
      this.axios
          .post(`${this.env.api_base_url}users/sign_in.json`, {
            jwt_token: this.makeJwtToken(this.email, this.password),
            // email: this.email,
            // password: this.password
          })
          .then(response => {
            if (response.data.result) {
              this.setFlash('ログインしました。')
              this.saveSession(response.data.user);

              // GA4 イベント
              this.ga4Event({user_id:  Number(response.data.user.number)})

              if (this.$route.query.redirect) {
                this.$router.push({path: this.$route.query.redirect})
              } else {
                this.$router.push({name: 'UsersCustomerTop'})
              }
            }
          })
          .catch((error) => {
            if (error.response.data.errors) {
              this.errors = error.response.data.errors
            } else if (error.response.data.error) {
              this.errors = [error.response.data.error]
            }
          });
    }
  }
}
</script>
